<template>
<div id="TopNovelGender" class="TopNovelGender mb-3">
    <div class="mb-1" style="paddingLeft:10px;font-size:18px">นิยายแนะนำ</div>

    <a-tabs type="card" default-active-key="1" @change="checkMode()">
        <a-tab-pane key="1" tab="ผู้ชาย">
            <a-list itemLayout="horizontal" :dataSource="novel_male">
                <a-list-item slot="renderItem" slot-scope="item, index" class="noOfReader">
                    <a-row style="width:100%">
                        <a-col :span="6">
                            <img v-if="index+1==1" width="20" height="20" src="./../../assets/first.png" />
                            <img v-else-if="index+1==2" width="20" height="20" src="./../../assets/second.png" />
                            <img v-else-if="index+1==3" width="20" height="20" src="./../../assets/third.png" />
                            <div v-else class="text">{{index+1}}</div>
                        </a-col>
                        <a-col :span="18" style="textAlign:left" class="text">
                            <a-list-item-content>
                                <div class="noveltitle" slot="title"  @click="goToNovel(item.novel_id)">{{item.novel_title}}</div>
                            </a-list-item-content>
                        </a-col>
                    </a-row>
                </a-list-item>
            </a-list>
        </a-tab-pane>
        <a-tab-pane key="2" tab="ผู้หญิง">
            <a-list itemLayout="horizontal" :dataSource="novel_female">
                <a-list-item slot="renderItem" slot-scope="item, index" class="noOfReader">
                    <a-row style="width:100%" @click="goToNovel(item.suggest_novel_id)">
                        <a-col :span="6">
                            <img v-if="index+1==1" width="20" height="20" src="./../../assets/first.png" />
                            <img v-else-if="index+1==2" width="20" height="20" src="./../../assets/second.png" />
                            <img v-else-if="index+1==3" width="20" height="20" src="./../../assets/third.png" />
                            <div v-else class="text">{{index+1}}</div>
                        </a-col>
                        <a-col :span="18" style="textAlign:left" class="text">
                            <a-list-item-content>
                                <div class="noveltitle" slot="title"  @click="goToNovel(item.novel_id)">{{item.novel_title}}</div>
                            </a-list-item-content>
                        </a-col>
                    </a-row>
                </a-list-item>
            </a-list>
        </a-tab-pane>
    </a-tabs>
</div>
</template>

<script>
import axios from 'axios'
import router from './../../router/index'
import {
    mapGetters
} from 'vuex'
export default {
    name: 'TopNovelGender',
    watch: {
        GET_MODE() {
            this.checkMode()
        }
    },
    computed: {
        ...mapGetters(['GET_MODE'])
    },
    data() {
        return {
            novel_male: [],
            novel_female: []
        }
    },
    async mounted() {
        const {
            data
        } = await axios.get('https://api2.novelrealm.com/module/home&submodule=top_novel_gender')
        //console.log(data)
        //console.log(data['male'])
        this.novel_male = data['male']
        this.novel_female = data['female']
        setTimeout(() => {
            this.checkMode()
        }, 10);
    },
    methods: {
        checkMode() {
            if (this.GET_MODE == 'darkmode') {
                this.darkmode()
            } else {
                this.lightmode()
            }
        },
        lightmode() {
            document.getElementById('TopNovelGender').classList.remove('darkmode')
            document.getElementsByClassName('text').forEach(element => {
                element.classList.remove('darkmodeText')
            });
        },
        darkmode() {
            document.getElementById('TopNovelGender').classList.add('darkmode')
            document.getElementsByClassName('text').forEach(element => {
                element.classList.add('darkmodeText')
            });
        },
        goToNovel(novel_id) {
            router.push("/novel/" + novel_id)
        }
    }
}
</script>

<style scoped>
.TopNovelGender {
    border-radius: 10px;
    background-color: #ffffff;
    width: auto;
    padding: 10px;
}

.noOfReader {
    font-size: 15px;
    text-align: center;
}

.darkmode {
    background-color: #35363A;
    color: #ffffff !important;
}

.darkmodeText, .darkmode .noveltitle, .darkmode .text {
    color: #ffffff !important;
}

.noveltitle{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.noveltitle:hover{
    cursor: pointer;
}

</style>
<style>
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active{
    color: #EE806B;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:hover{
    color: #EE806B;
}
</style>
